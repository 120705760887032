import {
  getDocumentById,
  addDocument,
  updateDocument,
  getDocSnapshot,
  profileUser,
  getAllUsersByPage,
  getAllData,
} from "../lib/firestoreOperations";
import { User } from "../types/userType";

const collectionName = "users";

export const createUser = async ({
  data,
  returnRef = false,
}: {
  data: any;
  returnRef?: boolean;
}) => {
  const result = await addDocument(collectionName, data);
  const documentData = { id: result.id, ...getDocSnapshot(result) };
  updateDocument(collectionName, result.id, documentData);
  return returnRef ? result : documentData;
};

export const updateUser = async ({ id, data }: { id: string; data: any }) => {
  return await updateDocument(collectionName, id, data);
};

export const getUserByAuthId = async (uid: string) => {
  return await getDocumentById(collectionName, "auth_id", "==", uid);
};

export const loggerSignIn = async (authUser: any) => {
  const userData = await profileUser(authUser);
  if (userData) {
    await updateDocument(collectionName, userData.id, userData);
  }
};

export const fetchAllUsers = async (rowsPerPage: number, lastVisible: any) => {
  const { users, totalCount, lastVisibleDoc } = await getAllUsersByPage(rowsPerPage, lastVisible);
  return { users, totalCount, lastVisibleDoc };
};

export const setAllUsersTOSFieldToFalse = async () => {
  try {
    // Fetch all users
    const users = (await getAllData(collectionName)) as unknown as [User];
    // Loop through each user and update `acceptedTOS` if the field exists
    let updatedCount = 0;
    for (const user of users) {
      if (Object.prototype.hasOwnProperty.call(user, "acceptedTOS")) {
        if (user.acceptedTOS != false) {
          await updateDocument(collectionName, user.id, { acceptedTOS: false });
          updatedCount++;
        }
      }
    }
    console.log(`${updatedCount} users updated with acceptedTOS set to false.`);
  } catch (error) {
    console.error("Error updating users' acceptedTOS field:", error);
  }
};
