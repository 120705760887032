import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { IoIosHelpCircleOutline } from "react-icons/io";

interface InfoBubbleProps {
  content: string;
}

export default function InfoBubble({ content }: InfoBubbleProps) {
  return (
    <Popover
      showArrow
      placement="left-start"
      classNames={{
        base: ["before:bg-[#1e293b] dark:before:bg-[#d1d5db]"],
        content: [
          "py-2 px-4 shadow-xl",
          "text-black text-white dark:bg-[#d1d5db] dark:text-black bg-[#1e293b]",
          "text-md",
        ],
      }}
    >
      <PopoverTrigger>
        <Button isIconOnly className="bg-white dark:bg-black">
          <IoIosHelpCircleOutline size={30} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="max-w-[650px] p-3 leading-6">{content}</PopoverContent>
    </Popover>
  );
}
