import { useMemo, useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
  Switch,
  Tooltip,
} from "@nextui-org/react";
import { SortDescriptor } from "@nextui-org/react";
import useSWR from "swr";
import { fetchAllUsers, setAllUsersTOSFieldToFalse, updateUser } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { CognitoUser } from "../../types/cognitoUserType";
import { User } from "../../types/userType";
import { IoMdClipboard } from "react-icons/io";

export default function Coupons(props: { isAuthenticated: boolean; userIdentity?: CognitoUser }) {
  const navigate = useNavigate();
  const [userPage, setUserPage] = useState(1);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [loadingState, setLoadingState] = useState<"loadingMore" | "idle">("loadingMore");
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResetTOSModalOpen, setIsResetTOSModalOpen] = useState(false);
  const [isResetTOSLoading, setIsResetTOSLoading] = useState(false);
  const [isAdminChecked, setIsAdminChecked] = useState(false);
  const [skipCheckoutChecked, setSkipCheckoutChecked] = useState(false);

  useEffect(() => {
    if (!props.isAuthenticated) navigate("/");
  }, [props.isAuthenticated]);

  const rowsPerPage = 10;

  const fetcher = async (rowsPerPage: number, lastVisible: any) => {
    const { users, totalCount, lastVisibleDoc } = await fetchAllUsers(rowsPerPage, lastVisible);
    if (lastVisibleDoc === null) {
      return;
    }
    return { users, totalCount, lastVisibleDoc };
  };

  const { data } = useSWR([``, rowsPerPage, lastVisible], () => fetcher(rowsPerPage, lastVisible), {
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data) {
        setAllUsers((prevUsers) => [...prevUsers, ...data.users]);
        setLastVisible(data.lastVisibleDoc);
        setLoadingState("idle");
      }
    },
  });

  const totalCount = data?.totalCount || 0;

  const sortedUsers = useMemo(() => {
    if (!sortDescriptor?.column) return allUsers;

    const sorted = [...allUsers].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof User];
      const second = b[sortDescriptor.column as keyof User];
      let cmp =
        (parseInt(first as string) || (first as keyof User)) <
        (parseInt(second as string) || (second as keyof User))
          ? -1
          : 1;
      return cmp;
    });

    return sorted;
  }, [allUsers, sortDescriptor]);

  const users = useMemo(
    () => sortedUsers.slice((userPage - 1) * rowsPerPage, userPage * rowsPerPage),
    [sortedUsers, userPage, rowsPerPage]
  );

  const totalPages = Math.ceil(totalCount / rowsPerPage);

  const manageUser = (user: User) => {
    setSelectedUser(user);
    if (user.is_admin) {
      setIsAdminChecked(user.is_admin);
    }
    if (user.skipCheckout) {
      setSkipCheckoutChecked(user.skipCheckout);
    }
    setIsModalOpen(true);
  };

  const handleSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
  };

  const copyIdToClipboard = async (id: string) => {
    try {
      await navigator.clipboard.writeText(id);
    } catch (err) {
      console.error("Failed to copy Id:", err);
    }
  };

  const updateUserData = async () => {
    if (selectedUser) {
      const updatedUser = {
        ...selectedUser,
        is_admin: isAdminChecked,
        skipCheckout: skipCheckoutChecked,
      };

      try {
        await updateUser({
          id: selectedUser.id,
          data: { is_admin: isAdminChecked, skipCheckout: skipCheckoutChecked },
        });
        setAllUsers((prevUsers) =>
          prevUsers.map((user) => (user.id === selectedUser.id ? updatedUser : user))
        );
        setIsModalOpen(false);
      } catch (error) {
        console.error("Failed to update user:", error);
      }
    }
  };

  const resetTOCForAllUsers = async () => {
    try {
      setIsResetTOSLoading(true);
      await setAllUsersTOSFieldToFalse();
      setIsResetTOSModalOpen(false);
    } catch (error) {
      console.error("Failed to reset TOS:", error);
    } finally {
      setIsResetTOSLoading(false);
    }
  };

  return (
    <section className="m- pb-10">
      <h1 className="text-3xl p-5 font-bold flex justify-center">ADMIN</h1>
      <Button
        className="my-5 text-base"
        isLoading={isResetTOSLoading}
        onPress={() => setIsResetTOSModalOpen(true)}
      >
        Reset Terms & Conditions
      </Button>
      <Table
        aria-label="User management table with pagination and skip checkout editing"
        selectionMode="single"
        sortDescriptor={sortDescriptor}
        onSortChange={handleSortChange}
        bottomContent={
          <div className="flex gap-2 justify-end items-center">
            <span>{`Displaying ${(userPage - 1) * rowsPerPage + 1} to ${Math.min(
              userPage * rowsPerPage,
              totalCount
            )} results out of ${totalCount}`}</span>
            {totalPages > 1 && (
              <Pagination
                loop
                isCompact
                showControls
                showShadow
                page={userPage}
                total={totalPages}
                onChange={(page) => setUserPage(page)}
              />
            )}
          </div>
        }
      >
        <TableHeader>
          <TableColumn className="text-md text-light font-bold" key="id" allowsSorting>
            Doc ID
          </TableColumn>
          <TableColumn className="text-md text-light font-bold" key="email" allowsSorting>
            Email
          </TableColumn>
          <TableColumn className="text-md text-light font-bold" key="name" allowsSorting>
            Name
          </TableColumn>
          <TableColumn className="text-md text-light font-bold" key="isAdmin">
            Is Admin
          </TableColumn>
          <TableColumn className="text-md text-light font-bold" key="skipCheckout">
            Skip Checkout
          </TableColumn>
          <TableColumn className="text-md text-light font-bold" key="action">
            Action
          </TableColumn>
        </TableHeader>
        <TableBody
          items={users}
          loadingState={loadingState}
          loadingContent={<Spinner label="Loading..." />}
          emptyContent={"No users to display."}
        >
          {(user) => (
            <TableRow key={user.id}>
              <TableCell>
                <Tooltip showArrow={true} content={user.id} placement="top-start" offset={-7}>
                  <Button variant="light" size="sm" onPress={() => copyIdToClipboard(user.id)}>
                    <IoMdClipboard className="text-lg" />
                  </Button>
                </Tooltip>
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.name ? user.name : "-"}</TableCell>
              <TableCell>{user.is_admin ? "Yes" : "No"}</TableCell>
              <TableCell>{user.skipCheckout ? "Yes" : "No"}</TableCell>
              <TableCell>
                <Button onPress={() => manageUser(user)}>Manage</Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Modal isOpen={isResetTOSModalOpen} onClose={() => setIsResetTOSModalOpen(false)}>
        <ModalContent>
          <ModalHeader>
            Are you sure you want to reset the TERMS AND CONDITIONS for all users?
          </ModalHeader>
          <ModalBody>
            <span className="text-danger">This action is irreversible!</span>
          </ModalBody>
          <ModalFooter>
            <Button onPress={() => setIsResetTOSModalOpen(false)}>Cancel</Button>
            <Button isLoading={isResetTOSLoading} onPress={resetTOCForAllUsers}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <ModalHeader>
            Manage User: {selectedUser?.name} ({selectedUser?.email})
          </ModalHeader>
          <ModalBody>
            <Switch isSelected={isAdminChecked} onValueChange={setIsAdminChecked}>
              Admin
            </Switch>
            <Switch isSelected={skipCheckoutChecked} onValueChange={setSkipCheckoutChecked}>
              Skip Checkout
            </Switch>
          </ModalBody>
          <ModalFooter>
            <Button onPress={() => setIsModalOpen(false)}>Cancel</Button>
            <Button onPress={updateUserData}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </section>
  );
}
